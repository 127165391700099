.container {
	border-radius: 4px;
	border: 1px solid #ccc;
	background-color: #f5f6fa;

	@media screen and (max-width: 1439px) {
		.mobileView {
			display: block;
		}

		.desktopView {
			display: none;
		}
	}

	@media screen and (min-width: 1440px) {
		.desktopView {
			display: block;
		}

		.mobileView {
			display: none;
		}
	}

	.regularTextBlock {
		font-size: 14px;
		margin: 0;
	}

	.desktopView {
		.mainContent {
			cursor: default;
			height: 64px;
			padding: 16px;
			display: grid;
			grid-template-columns: 225px 1fr 70px 140px 37px 68px 24px;
			align-items: center;
			gap: 10px;

			.headBlock {
				display: grid;
				grid-template-columns: auto 1fr;
				align-items: center;
				gap: 8px;
			}

			.dateOfCreateBlock {
			}

			.statusBlock {
				min-width: 0;
			}

			.priceBlock {
				.price {
					margin: 0;
					font-size: 14px;
					font-weight: 500;
				}
			}

			.actionBlock {
			}

			.productPhotosBlock {
				height: 32px;
				overflow: hidden;
			}

			.toggleBlock {
				display: flex;
				align-items: center;
				& > img {
					cursor: pointer;
					transform: rotate(90deg);
					transition: transform 250ms ease-in-out;
					width: 24px;
				}

				&[data-opened='true'] img {
					transform: rotate(-90deg);
				}
			}
		}
	}

	.productMessageBlock {
	}

	.mobileView {
		.mainContent {
			cursor: default;
			padding: 12px;
			display: flex;
			flex-direction: column;
			gap: 12px;

			.row {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.checkBox {
				padding: 3px;

				input {
					width: 18px;
					height: 18px;
				}
			}
		}
	}
}
