.container {
	padding: 0 30px 30px 30px;
	color: black;
	text-align: center;
	max-width: 624px;

	@media screen and (max-width: 992px) {
		padding: 0 16px 16px 16px;
	}
}

.iconBlock {
	display: flex;
	justify-content: center;
	margin-bottom: 16px;

	& > img {
		width: 120px;
		height: 120px;
	}
}

.titleBlock {
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 40px;
	margin-bottom: 16px;

	@media screen and (max-width: 992px) {
		font-size: 20px;
		line-height: 24px;
	}
}

.description {
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	margin-bottom: 24px;
	overflow-wrap: break-word;
}

.buttonBlock {
	display: flex;
	gap: 20px;

	button {
		max-width: 311px;
	}
}

.buttonBlock > div {
	width: 100%;
}

@media screen and (max-width: 768px) {
	.buttonBlock {
		flex-direction: column;
	}
}
