.container {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.title,
	.title > span {
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		color: var(--primary-black);

		position: relative;

		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
		gap: 4px;
	}
}
