.container {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.titleBlock {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 4px;

		.title {
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			color: var(--primary-black);
		}
	}

	.errorText {
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		color: var(--primary-red);

		margin-bottom: 8px;
	}
}
