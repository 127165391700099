.container {
	background-color: #ffffff;
	border-radius: 4px;
	padding: 24px;
	z-index: 1;
}

.container[data-clickable='true'] {
	cursor: pointer;
}

.container[data-background='light-blue'] {
	background-color: var(--blue-0);
}

.container[data-background='light-grey'] {
	background-color: var(--background-block-light-grey);
}

.container[data-box-shadow='true'] {
	box-shadow: 0 1px 3px 1px rgba(10, 36, 50, 0.12);
}
