.emptyBlock {
	display: flex;
	justify-content: center;
	height: 100%;

	.contentContainer {
		max-width: 454px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.icon {
			margin-bottom: 32px;
			width: 118px;
			height: 118px;
		}

		.title {
			text-align: center;
			font-size: 32px;
			font-weight: 700;
			line-height: 1.25;
			margin: 0 0 15px 0;
		}

		.description {
			margin: 0 0 30px 0;
			text-align: center;
			font-size: 14px;
			line-height: 1.5;
		}

		.goToCatalogButton {
			text-decoration: none;
			color: #ffffff;
			padding: 12px 20px;
			border-radius: 4px;
			background-color: #ec1944;
			margin: 0 auto;
		}
	}
}
