
.pageTitle {
	font-size: 32px;
	font-weight: 700;
	line-height: 1.25;
    margin-top: 0;
	margin-bottom: 20px;
}

.filters {
	margin-bottom: 16px;
	display: flex;
	justify-content: space-between;
	gap: 10px;
}

.list {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.emptyBlock {
	display: flex;
	justify-content: center;
	height: 100%;
	padding-top: 100px;

	.contentContainer {
		max-width: 454px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.icon {
			margin-bottom: 32px;
			width: 118px;
			height: 118px;
		}

		.title {
			margin-bottom: 15px;

			text-align: center;
			font-size: 32px;
			font-weight: 700;
			line-height: 1.25;
		}

		.description {
			margin-bottom: 30px;

			text-align: center;
			font-size: 14px;
			line-height: 1.5;
		}

		.goToCatalogButton {
			text-decoration: none;
			color: #ffffff;
			padding: 12px 20px;
			border-radius: 4px;
			background-color: #ec1944;
			margin: 0 auto;
		}
	}
}
