.commentBlock {
	padding: 24px 16px;

	.title {
		font-size: 14px;
		font-weight: 500;
		line-height: 1.14;
	}

	.commentBlockList {
		max-height: 500px;
		overflow-y: auto;

		margin-bottom: 24px;

		.comment {
			display: flex;
			flex-direction: column;
			gap: 6px;

			font-size: 14px;
			line-height: 1.14;
		}
	}

	@media screen and (max-width: 1439px) {
		padding: 12px 12px;
	}
}

.link {
	text-decoration: underline;
	color: #0e90fb;
}
