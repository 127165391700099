.container {
	border-top: 1px solid var(--secondary-light-grey);
	border-bottom: 1px solid var(--secondary-light-grey);
	background: var(--background-block-light-grey);
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 12px 0;
	word-break: break-all;

	.boldTextBlock {
		margin: 0;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.3;
	}

	.smallBoldTextBlock {
		margin: 0;
		font-size: 12px;
		font-weight: 500;
	}

	.regularTextBlock {
		margin: 0;
		font-size: 14px;
		line-height: 1.3;
	}

	.smallRegularTextBlock {
		margin: 0;
		font-size: 12px;
	}

	.link {
		text-decoration: none;
		margin: 0;
		cursor: pointer;
		color: var(--secondary-blue);
		text-overflow: ellipsis;
		word-break: break-all;
		font-size: 12px;
		line-height: 1.3;
	}

	.row {
		padding: 0 12px;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		column-gap: 20px;
	}

	.verticalRow {
		padding: 0 12px;
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	.discussionBlock {
		display: grid;
		gap: 8px;
		grid-template-columns: 1fr 1fr;
	}

	.cancelReasonBlock {
		padding: 0 12px;
		color: var(--primary-grey);

		@media screen and (max-width: 419px) {
			font-size: 12px;
		}
	}

	.priceBlock {
		padding: 0 12px;
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 4px 16px;

		p:nth-child(even) {
			text-align: right;
		}
	}
}
