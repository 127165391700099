.container {
	border-radius: 4px;
	border: 1px solid #ccc;

	@media screen and (max-width: 1439px) {
		.mobileView {
			display: block;
		}

		.desktopView {
			display: none;
		}
	}

	@media screen and (min-width: 1440px) {
		.desktopView {
			display: block;
		}

		.mobileView {
			display: none;
		}
	}

	.regularTextBlock {
		font-size: 14px;
		margin: 0;
	}

	.additionalContentContainer {
		overflow: hidden;
		transition:
			height 250ms ease-in-out,
			opacity 250ms ease-in-out;
	}

	.actionButton {
		text-decoration: none;
		margin: 0;
		user-select: none;
		cursor: pointer;
		color: var(--secondary-blue);
		font-size: 14px;
		font-weight: 500;
		white-space: nowrap;
	}

	.desktopView {
		.mainContent {
			cursor: pointer;
			height: 64px;
			padding: 16px;
			display: grid;
			grid-template-columns: 225px 1fr 70px 140px 37px 68px 24px;
			align-items: center;
			gap: 10px;

			.headBlock {

				display: grid;
				grid-template-columns: auto 1fr;
				align-items: center;
				gap: 8px;

				.checkBox {
				}
			}

			.dateOfCreateBlock {}

			.statusBlock {
				min-width: 0;
			}

			.priceBlock {

				.price {
					margin: 0;
					font-size: 14px;
					font-weight: 500;
				}
			}

			.actionBlock {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				gap: 4px;
				align-items: flex-start;
			}

			.productPhotosBlock {
				height: 32px;
				overflow: hidden;
			}

			.toggleBlock {
				display: flex;
				align-items: center;
				& > img {
					cursor: pointer;
					transform: rotate(90deg);
					transition: transform 250ms ease-in-out;
					width: 24px;
				}

				&[data-opened='true'] img {
					transform: rotate(-90deg);
				}
			}
		}
	}

	.productMessageBlock {
		position: relative;

		& > img {
			width: 24px;
			height: 24px;
		}

		.productNewMessage {
			position: absolute;
			top: -10px;
			right: 3px;
			&[data-mobile='true'] {
				right: -7px;
			}

			display: flex;
			align-items: center;
			justify-content: center;

			width: 22px;
			height: 22px;

			background: white;
			border-radius: 50%;

			& > div {
				display: flex;
				align-items: center;
				justify-content: center;

				font-size: 12px;
				color: white;

				width: 18px;
				height: 18px;

				background: red;
				border-radius: 50%;
			}
		}
	}

	.mobileView {
		.mainContent {
			cursor: pointer;
			padding: 12px;
			display: flex;
			flex-direction: column;
			gap: 12px;

			.row {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.checkBox {
				padding: 3px;

				input {
					width: 18px;
					height: 18px;
				}
			}

			.middleRow {
				display: grid;
				grid-template-columns: auto 1fr;
				justify-content: flex-start;
				gap: 16px;
			}
		}

		.moreContainer {
			.toggleBlock {
				padding: 0 12px 12px;
				margin: 0;
				user-select: none;
				cursor: pointer;
				color: var(--secondary-blue);
				font-size: 14px;
				line-height: 1.57;
				display: flex;
				justify-content: center;
				gap: 4px;

				& > img {
					cursor: pointer;
					transform: rotate(0deg);
					transition: transform 250ms ease-in-out;
					width: 24px;
				}

				&[data-opened='true'] img {
					transform: rotate(-180deg);
				}
			}
		}
	}
}
