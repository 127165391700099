.container {
	padding: 8px 16px 40px;
	word-break: break-all;

	.regularTextBlock {
		margin: 0;
		font-size: 14px;
		line-height: 1.14;
	}

	.lightText {
		color: var(--primary-light-grey);
	}

	.boldTextBlock {
		margin: 0;
		font-size: 16px;
		font-weight: 500;
		line-height: 1;
	}

	.infoContainer {
		&[data-has-discussion='true'] {
			display: flex;
			gap: 24px;

			.infoBlock {
				width: calc(50% + 80px);
			}

			.discussionBlock {
				width: calc(50% - 80px);
				display: grid;
				gap: 8px;
				grid-template-columns: 1fr 1fr;
				align-items: center;
			}
		}

		.infoBlock {
			display: grid;
			grid-template-columns: auto 1fr;
			gap: 6px 24px;
			margin-bottom: 24px;
		}
	}

	.footerBlock {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.priceBlock {
		margin-left: auto;
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 6px 24px;
		margin-top: 24px;

		p:nth-child(even) {
			text-align: right;
		}
	}

	.cancelReasonBlock {
		color: var(--primary-grey);
	}
}
