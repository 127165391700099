.container {
	position: relative;

	.input {
		padding-right: 40px;
	}

	.sendIcon {
		width: 24px;
		height: 24px;
		background-repeat: no-repeat;
		position: absolute;
		top: 10px;
		right: 12px;

		&[aria-disabled='false'] {
			cursor: pointer;
		}
	}
}
