.errorContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	text-align: center;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;

	.errorContent {
		padding: 20px;

		.errorTitle {
			font-size: 3rem;
			color: #dc3545;
			margin: 0;
		}

		.errorSubTitle {
			font-size: 1.5rem;
			color: #dc3545;
			margin-top: 0;
			margin-bottom: 15px;
		}

		.errorDescription {
			font-size: 1rem;
			color: #555;
			margin-bottom: 20px;
		}

		.retryButton {
			background-color: #007bff;
			color: white;
			border: none;
			padding: 10px 20px;
			font-size: 1rem;
			border-radius: 5px;
			cursor: pointer;
			transition: background-color 0.3s ease;

			&:hover {
				background-color: #0056b3;
			}
		}
	}
}

@media (max-width: 768px) {
	.errorContainer {
		max-width: 90%;

		.errorContent {
			.errorTitle {
				font-size: 1.5rem;
			}

			.errorDescription {
				font-size: 0.9rem;
			}

			.retryButton {
				font-size: 0.9rem;
			}
		}
	}
}
