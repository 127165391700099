.pageTitleWrap {
	/*height: 74px; //36height + (24margin*2)*/
	/*height: 38px;*/

	display: flex;
	align-items: flex-start;
	padding: 0 0;
	margin-top: 16px;
	margin-bottom: 6px;

	position: sticky;
	top: 0;
	z-index: 2;
	background-color: var(--background-body);
	box-shadow: 0 -5px 5px 10px var(--background-body);

	img:hover {
		cursor: pointer;
	}

	.title {
		font-weight: 600;
		font-size: 30px;
		line-height: 1.27;
		color: var(--primary-black);
		margin-bottom: 0;
	}

	@media screen and (max-width: 767px) {
		margin-bottom: 16px;
		margin-top: 16px;
		height: 32px;
		top: unset;
	}
}

.statusList {
	padding: 16px 0;
	position: sticky;
	top: 0;
	z-index: 2;
	background-color: var(--background-body);

	box-shadow: 0 10px 5px 10px var(--background-body);

	@media screen and (max-width: 767px) {
		padding: 16px 20px;
		box-shadow: 0 -10px 5px 10px var(--background-body);
	}
}

.header {
	padding: 0 0;
	margin-bottom: 4px;

	position: sticky;
	top: 66px;
	z-index: 2;
	background-color: var(--background-body);

	@media screen and (max-width: 767px) {
		display: none;
	}
}

.list {
	display: flex;
	height: 100%;
	flex-direction: column;

	list-style: none;
	margin: 0;
	padding: 0 0;
	gap: 4px;

	li {
	}

	@media screen and (max-width: 767px) {
		gap: 8px;
	}
}

.miniOrder {
	display: none;

	&[data-menu-minimize='false'] {
		@media screen and (max-width: 1365px) {
			display: flex;
		}
	}

	&[data-menu-minimize='true'] {
		@media screen and (max-width: 1119px) {
			display: flex;
		}
	}
}

.archiveTitle {
	padding: 20px 32px;
	display: flex;
	align-items: center;
	gap: 32px;

	white-space: nowrap;
	color: var(--primary-grey);
}

.archiveButtonWrap {
	@media (max-width: 767px) {
		padding: 16px 16px 80px;
	}
	padding: 32px 32px 20px;

	display: flex;
	justify-content: center;

	.openArchive {
		width: max-content;
		padding-bottom: 3px;
	}
}

.actionsContainer {
	z-index: 1;
	position: fixed;
	width: 100%;

	transform-origin: bottom;

	filter: drop-shadow(0px 8px 10px rgba(10, 36, 50, 0.2));

	@media screen and (max-width: 767px) {
		&[data-show='false'] {
			bottom: -100%;
		}

		&[data-show='true'] {
			transition: bottom 250ms ease-in-out;
			bottom: 20px;
		}
	}

	@media screen and (min-width: 768px) {
		transition: bottom 350ms ease-in-out;

		&[data-show='false'] {
			bottom: -100%;
		}

		&[data-show='true'] {
			bottom: 70px;
		}
	}
}

.paginationContainer {
	user-select: none;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;

	padding: 30px;

	p {
		margin: 0;
	}

	.pageNumber {
		font-size: 15px;
		line-height: 1.46;
		cursor: pointer;

		&[data-active='true'] {
			color: #0e90fb;
		}
	}

	.pagination {
		display: flex;
		align-items: center;
		gap: 16px;

		@media screen and (max-width: 991px) {
			gap: 13px;
		}

		p:not(.threeDots) {
			cursor: pointer;

			&:hover {
				font-weight: 500;
			}
		}
	}

	.paginationArrow {
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 4px;
		font-size: 12px;
		line-height: 1.33;

		&:hover {
			font-weight: 500;
		}
	}

	.leftArrow {
		img {
			transform: rotateY(180deg);
		}
	}
}
