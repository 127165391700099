.container {
    position: relative;
	padding: 24px 0;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	row-gap: 24px;

    @mixin smaller-than $mantine-breakpoint-md {
        row-gap: 20px;
    }

	& > h2 {
        font-weight: 500;
        font-size: 40px;
        line-height: 60px;
		color: #333333;
		margin: 0;

        @mixin smaller-than $mantine-breakpoint-md {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
        }
	}

	.page {
		display: grid;
		grid-template-columns: 1fr 317px;
		column-gap: 26px;

        @mixin smaller-than $mantine-breakpoint-md {
			grid-template-columns: 1fr;
			gap: 16px;
		}

		.title {
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: keep-all;
            span{
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
            }
		}

		.productInfo {
			display: flex;
			flex-direction: column;
			gap: 6px;

			font-size: 14px;
			line-height: 16px;


            @mixin smaller-than $mantine-breakpoint-md {
				font-size: 12px;
			}

            ul{
                padding: 0;
                margin:0;
                list-style-type: none;
                li{
                    display: grid;
                    grid-template-columns: 1fr 3fr;
                    gap: 4px;
                    @mixin smaller-than $mantine-breakpoint-md {
                        grid-template-columns: 1fr 3fr;
                    }
                    span{
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;


                        &:first-child{
                            color: var(--grey-4);
                        }
                        &:last-child{
                            @mixin smaller-than $mantine-breakpoint-md {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }
		}

		.listBlock {
			display: flex;
			flex-direction: column;
			gap: 4px;

			.blockTitleLabel {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
				margin-top: 0;
				margin-bottom: 4px;
			}

			.input {
				border: 1px solid var(--grey-2);
                border-radius: 12px;

                &::placeholder {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 28px;
                    color: var(--grey-4);
                }
			}

			.additionalInfo {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: var(--grey-4);

                @mixin smaller-than $mantine-breakpoint-md {
                    font-size: 12px;
                }
			}

			.descriptionBlock {
				margin-bottom: 20px;
			}

            .blockTitle {
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                margin-top: 0;
                margin-bottom: 12px;

                @mixin smaller-than $mantine-breakpoint-md {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 28px;
                }
            }

			.listItem {
				border: 1px solid var(--grey-2);
				border-radius: 12px;
				padding: 24px;

				display: flex;
				flex-direction: column;
				gap: 16px;

                @mixin smaller-than $mantine-breakpoint-md {
                    padding: 16px 12px;
                }

				.info {
					display: grid;
					grid-template-columns: 140px 1fr;
					gap: 20px;

                    @mixin smaller-than $mantine-breakpoint-lg {
						grid-template-columns: 1fr;
					}

					.image {
                        overflow: hidden;
						.imgItem {
							width: 140px;
							height: 140px;
                            border-radius: 12px;
						}

						.titleMobileBlock {
							display: flex;
							flex-direction: column;
							gap: 16px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            word-break: keep-all;
                            @mixin smaller-than $mantine-breakpoint-lg {
                                gap: 8px;
                            }
                            span{
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 24px;
                            }
						}

                        @mixin smaller-than $mantine-breakpoint-lg {
							display: flex;
							gap: 12px;

							.imgItem {
								width: 60px;
								height: 60px;
							}
						}
					}

					.description {
						display: flex;
						flex-direction: column;
						gap: 8px;
                        max-width: 100%;
                        overflow: hidden;

						.dividerDesktop {
                            @mixin smaller-than $mantine-breakpoint-md {
								display: none;
							}
						}

						.dividerMobile {
                            @mixin larger-than $mantine-breakpoint-md {
								display: none;
							}
						}

						.status {
							width: fit-content;
							display: inline-block;
                            margin-right: 12px;

                            @mixin smaller-than $mantine-breakpoint-md {
								display: none;
							}
						}

						.detailsBlock {
							display: grid;
							grid-template-columns: 1fr auto 1fr;

							gap: 32px;

                            @mixin smaller-than $mantine-breakpoint-lg {
                                gap: 0;
								grid-template-columns: 1fr;
							}

							.qtyPriceBlock {
                                padding: 16px 8px;
								display: flex;
								justify-content: space-between;

								> div {
									display: flex;
									flex-direction: column;
									align-items: center;
									gap: 6px;
								}

								> div > span {
									font-size: 14px;
								}
								> div > span:first-child {
									color: var(--grey-4);
								}
								> div > span:nth-child(2) {
								}
							}
						}
					}
				}

				.services {
					display: flex;
					flex-direction: column;
					gap: 8px;
				}

				.summary {
					display: grid;
					grid-template-columns: 1fr;
					align-items: center;

                    @mixin smaller-than $mantine-breakpoint-md {
						grid-template-columns: 1fr;
					}

					.itemSummary {
						display: flex;
						flex-direction: column;
						align-items: flex-end;

						> div {
							width: 100%;
							max-width: 320px;
							margin: 0;
							display: grid;
                            grid-template-columns: 5fr 2fr;

                            font-weight: 400;
                            font-size: 14px;
                            line-height: 24px;
                            color: var(--grey-4);


                            > span:nth-child(2) {
								white-space: nowrap;
                                color: var(--grey-9);
							}
						}

						> div:last-child * {
							font-weight: 600;
                            color: var(--grey-9);

						}
					}
				}
			}
		}

		.totalBlock {
			display: grid;
			grid-template-rows: auto auto 1fr;
			gap: 12px;
			margin-top: 40px;

            @mixin smaller-than $mantine-breakpoint-md {
				margin-top: 0;
			}

			.payBlock {
				border: 1px solid #dfdfdf;
				border-radius: 4px;

				display: grid;
				grid-template-rows: auto auto 1fr;
				gap: 12px;

				.toPay {
					padding: 12px 16px 0;

					p {
						margin: 0;
						font-size: 15px;
					}

					strong {
						font-size: 40px;
					}
				}

				.descPay {
					padding: 0 16px;

					p {
						margin: 0;
						font-size: 15px;
					}
				}
			}

			.button {
				height: 48px;
				border-radius: 4px;
				padding: 12px 24px;
				border: none;

				transition: background-color 150ms ease-in-out;
			}

			.buttonPay {
				background-color: #ec1944;
				color: white;

				&:hover {
					background-color: #d30d36;
				}

				&:active {
					background-color: #b60327;
				}
			}

			.buttonSave {
				background-color: white;
				color: var(--secondary-blue);
				border: 1px solid var(--secondary-blue);

				&:hover {
					border-color: var(--secondary-blue);
					background-color: var(--secondary-blue);
					color: #ffffff;
				}

				&:active {
					border-color: var(--secondary-blue);
					background-color: var(--secondary-blue);
					color: #ffffff;
				}
			}

			.info {
				display: flex;
				gap: 6px;

				> img {
					width: 16px;
					height: 16px;
				}

				> span {
					font-size: 12px;
					color: var(--secondary-blue);
				}
			}
		}
	}
}

.additional {
	position: absolute;
}
