.container {
	position: relative;

	.input {
		padding-right: 38px;
	}

	& > a {
		position: absolute;
		right: 12px;
		top: 10px;
		cursor: pointer;
	}
}
