.tooltipIcon {
	position: relative;
	display: inline;
	height: 24px;
}
.tooltipIcon:hover .tooltip {
	transform: rotate3d(0, 0, 0, 90deg);
}
.tooltipIcon > .tooltip {
	transform: rotate3d(1, 0, 0, 90deg);
	transform-origin: bottom;
	transition: all 300ms ease-in-out;
	font-weight: 400;
	font-size: 12px;
	line-height: 22px;
	padding: 8px;
	width: 262px;
	position: absolute;
	bottom: 30px;
	left: 0;
}
@media screen and (max-width: 1024px) {
	.tooltipIcon > .tooltip {
		left: -131px;
	}
}

/*# sourceMappingURL=tooltip.block.module.css.map */
