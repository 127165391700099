.container {
	display: flex;
	align-items: stretch;
	border: 1px solid var(--tabs-border-color);
	border-radius: 4px;
	padding: 6px;
	position: relative;
}

.containerDisabled {
	background-color: var(--tab-disabled);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.4;
}

.tab {
	padding: 8px 12px;
	background-color: var(--white);
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: var(--primary-light-grey);
	cursor: pointer;
	margin-right: 4px;
	text-align: center;

	@media screen and (max-width: 1024px) {
		font-size: 12px;
		line-height: 16px;
		word-break: break-all;
	}
}

.tab:last-child {
	margin-right: 0;
}

.tab:nth-last-child(1) {
	margin-right: 0;
}

.tab[data-active='true'] {
	background-color: var(--secondary-blue);
	color: var(--white);
}

.tabDivider {
	height: 100%;
	width: 1px;
	background-color: var(--secondary-grey);
	margin-right: 4px;
}

.tabDividerSmall {
}

.tabDividerLarge {
}

.tabSmall {
	flex: 1;
	min-height: 40px;
}

.tabLarge {
	flex: 1;
	min-height: 60px;
}

.dFlex {
	display: flex;
}
