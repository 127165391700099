.listContainer {
	display: grid;
	overflow-x: auto;
	@media screen and (max-width: 991.98px) {
		overflow-x: auto;
	}
	@media screen and (max-width: 767.98px) {
		white-space: nowrap;
		width: calc(100% + 140px);
	}
}

.list {
	list-style: none;
	margin: 0;
	padding: 0;

	display: flex;
	gap: 32px;
	border-bottom: 1px solid #cccccc;

	@media screen and (max-width: 1200px) {
		gap: 22px;
	}
	@media screen and (max-width: 767px) {
		margin-right: 140px;
	}
}

.item {
	position: relative;
	font-size: 14px;
	line-height: 1.5;
	padding-bottom: 4px;
	user-select: none;

	&[data-clickable='true'] {
		cursor: pointer;
	}

	&[data-active='true'] {
		color: #0061af;
	}

	&[data-active='true']::after {
		display: block;
		content: '';
		position: absolute;
		width: 100%;
		height: 1px;
		bottom: -1px;
		background-color: #0061af;
	}
}
