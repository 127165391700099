.formInput {
	border-radius: 4px;
	border: 1px solid var(--secondary-grey);
	background: #ffffff !important;
	height: 44px;
	/*min-width: 281px;*/
	padding-top: 15px;
	padding-left: 12px;
	padding-right: 12px;
	width: 100%;
	color: #333333;
	&[data-variant='noLabel'] {
		padding-top: 0;
	}
	position: static !important;

	&[data-variant='profile'] {
		border-radius: 8px;
		border: 1px solid #dbe4f0;

		&:disabled {
			color: #758295;
		}
	}
}

.formInput:-webkit-autofill {
	background: #ffffff !important;
	transition: background-color 5000s ease-in-out;
}

.formInputAutoFill[data-disable-fill-color='true'] {
	-webkit-text-fill-color: white;
}

.formInput:focus,
.formInput:focus-visible {
	border-color: var(--input-border-focus-color);
	outline: none;
}

.formInput:disabled {
	background-color: var(--input-disabled) !important;
}
.formInput[data-variant='profile'] {
	&[data-without-border='true'] {
		border: none;
	}
}
.labelPlaceholder {
	text-wrap: nowrap;
	text-overflow: ellipsis;
	max-width: calc(100% - 24px);
	overflow: hidden;

	&:hover:not([data-disabled='true']) {
		z-index: 1000;
		max-width: unset;
		background: white;
		padding-right: 10px;
	}

	font-size: 14px;
	position: absolute;
	left: 12px;
	top: 12px;
	color: #959595;
	transition: all 200ms ease-in-out;
}

.container {
	position: relative;
	&[data-variant='profile'] {
		.labelPlaceholder {
			color: var(--primary-grey);
		}
	}
}

.labelPlaceholder[data-focus='true'] {
	font-size: 12px;
	top: 5px;
}

.isInvalid > input {
	border-color: var(--primary-red) !important;
}

.isInvalid > input:focus,
.isInvalid > input:focus-visible {
	border-color: var(--primary-red) !important;
}

.isInvalid ~ .errorInput {
	color: var(--primary-red) !important;
	font-size: 12px !important;
	margin-left: 1px !important;
	transition: all 0.25s linear !important;
}

.clearButton {
	position: absolute;
	top: 22px;
	right: 22px;
	transform: translate(50%, -50%);
	cursor: pointer;
}

.errorContainer {
    color: var(--primary-red) !important;
    font-size: 12px !important;
    margin-left: 1px;
    transition: height 300ms ease-in-out !important;
}