.title {
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 40px;
	color: #333;
}

.titleBlock {
	display: grid;
	grid-template-columns: 1fr;
	gap: 12px;

	& > div {
		display: grid;
		grid-template-columns: 1fr;
		gap: 4px;
	}

	.subtitle {
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
	}

	.titleLink {
		font-size: 14px;
		font-weight: 500;
		color: #0061af;
		line-height: 22px;
		cursor: pointer;

		&:hover {
			text-decoration-line: underline;
		}
	}
}

.form {
	display: grid;
	gap: 20px;

	.tabs {
		grid-area: 1 / 1 / 2 / 3;
	}

	.namePrice {
		grid-area: 2 / 1 / 2 / 3;
		display: grid;
		grid-template-columns: 1fr 2fr;
		gap: 20px;
	}

	.coinRefresh {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 8px;
		width: 116px;
		color: var(--primary-blue);
		font-size: 12px;
		cursor: pointer;

		& > img {
			width: 24px;
			height: 24px;
		}
	}

	.linkProduct {
		grid-area: 3 / 1 / 3 / 3;
	}

	.orderPaymentLink {
		grid-area: 4 / 1 / 4 / 3;
	}

	.linkQrCode {
		grid-area: 5 / 1 / 5 / 3;
	}

	.summary {
		grid-area: 6 / 1 / 6 / 3;

		text-align: right;
		display: inline-flex;
		justify-content: flex-end;
		flex-direction: column;
		gap: 4px;

		span:nth-child(1),
		span:nth-child(2) {
			font-size: 12px;
			font-weight: 500;
			color: var(--primary-light-grey);
		}
		span:nth-child(3),
		span:nth-child(4) {
			font-size: 18px;
			font-weight: 600;
			color: var(--primary-text-dark);
		}
	}

	.buttons {
		grid-area: 7 / 1 / 7 / 1;

		display: grid;
		gap: 20px;

		grid-template-columns: 250px 150px;
	}

	@media screen and (max-width: 991.98px) {
		gap: 12px;

		.namePrice {
			grid-area: 2 / 1 / 2 / 3;

			display: grid;

			& > div:nth-child(1) {
				grid-area: 1 / 1 / 1 / 4;
			}

			& > div:nth-child(2) {
				grid-area: 2 / 1 / 2 / 4;
			}
		}

		.coinRefresh {
		}

		.linkProduct {
			grid-area: 3 / 1 / 3 / 3;
		}

		.orderPaymentLink {
			grid-area: 4 / 1 / 4 / 3;
		}

		.linkQrCode {
			grid-area: 5 / 1 / 5 / 3;
		}

		.summary {
			grid-area: 6 / 1 / 6 / 3;
		}

		.buttons {
			grid-area: 7 / 1 / 7 / 3;
			gap: 8px;
			grid-template-columns: 1fr;
		}
	}
}
